import {editMyInfoConstants} from "../../config/constants";
import {ConnectionService} from "../../services/connection.service";

export const editMyInfoActions = {
	openEditMyInfoRequest,
	closeEditMyInfoRequest,
	submitEditMyInfoRequest,
	openEditMyInfoPopup,
	closeEditMyInfoPopup,
	verifyEditMyInfoToken,
	submitEditMyInfoForm
};

function openEditMyInfoRequest() {
	return {
		type: editMyInfoConstants.OPEN_EDIT_MY_INFO_REQUEST
	}
}

function closeEditMyInfoRequest() {
	return {
		type: editMyInfoConstants.CLOSE_EDIT_MY_INFO_REQUEST
	}
}

function verifyEditMyInfoToken(editToken) {
	return dispatch => {
		ConnectionService.verifyEditMyInfoToken(editToken)
			.then(async response => {
				if (response.ok) {
					const body = await response.json();

					dispatch(openEditMyInfoPopup(body.results, editToken));
				} else {
					//Invalid token, clear out the URL params
					window.history.replaceState({}, document.title, '/');
				}
			});
	};
}

function submitEditMyInfoRequest(formData) {
	return dispatch => {
		ConnectionService.submitEditMyInfoRequest(formData)
			.then(response => {
					if (response.ok) {
						dispatch(success());
					}
				},
				error => {
					//Do nothing
				}
			);
	};

	function success(response) {
		return {type: editMyInfoConstants.EDIT_MY_INFO_REQUEST_ACCEPTED};
	}
}

function openEditMyInfoPopup(editingConnection, editRequestToken) {
	return {
		type: editMyInfoConstants.OPEN_EDIT_MY_INFO_POPUP,
		editingConnection,
		editRequestToken
	}
}

function closeEditMyInfoPopup() {
	return {
		type: editMyInfoConstants.CLOSE_EDIT_MY_INFO_POPUP
	}
}

function submitEditMyInfoForm(formData) {
	return dispatch => {
		ConnectionService.submitEditMyInfoForm(formData)
			.then(response => {
					if (response.ok) {
						dispatch(success());
					}
				}
			);
	};

	function success(response) {
		return {type: editMyInfoConstants.EDIT_MY_INFO_ACCEPTED};
	}
}
