import rootReducer from "./reducers/root.reducer";
import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
	rootReducer,
	composeEnhancers(
		applyMiddleware(
			thunkMiddleware,
		)
	)
);
