import React from 'react';
import './App.css';

import {HomePage} from "./components/home-page/home-page.component";
import Provider from "react-redux/lib/components/Provider";
import {store} from "./redux/store";
import CssBaseline from "@material-ui/core/CssBaseline";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';

class App extends React.Component {
	render() {
		return <Provider store={store}>
			<CssBaseline/>
			<HomePage />
		</Provider>
	}
}

export default App;
