import connect from "react-redux/lib/connect/connect";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import React from "react";
import TextField from "@material-ui/core/TextField";
import {connectionsActions} from "../../redux/actions/connections.actions";
import {assign, isEmpty} from 'lodash';
import {ConnectionService} from "../../services/connection.service";
import notify from "devextreme/ui/notify";
import {CONNECTION_STATUS, REGISTRATION_REJECTED} from "../../config/constants";

class _RejectConnectionComponent extends React.Component {
	state = {
		rejectionReason: ''
	};

	handleChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const {rejectionReason}     = this.state,
		      {rejectingConnection} = this.props.connections,
		      {dispatch}            = this.props;

		if (!isEmpty(rejectionReason)) {
			ConnectionService.updateConnection(assign({}, rejectingConnection, {
				status:   CONNECTION_STATUS.REJECTED,
				rejected: REGISTRATION_REJECTED, //Need to state this explicitly since it triggers an e-mail and we want to be sure to send that only once
				rejectionReason
			}))
				.then(response => {
					if (response.success) {
						this.getConnections();


						notify({
							message:  'Connection Rejected',
							position: {
								at:     'top center',
								offset: '0 60'
							}
						}, 'success', 3000);

						dispatch(connectionsActions.closeRejectionPopup());
					}
				});
		}

		return false;
	};

	getConnections = () => {
		const {loggedIn} = this.props.authentication;

		this.props.dispatch(connectionsActions.getConnections(!!loggedIn));
	};

	render() {
		const {rejectionReason} = this.state;

		return (
			<Container component="main" maxWidth="sm">
				<p>You may provide a more detailed rejection reason below, which will be sent via e-mail to the person
					who registered.</p>

				<TextField
					label="Rejection reason"
					multiline
					rowsMax="4"
					name="rejectionReason"
					value={rejectionReason}
					onChange={this.handleChange}
					margin="normal"
					variant="filled"
					fullWidth={true}
					rows={7}
				/>

				<Button
					type="button"
					fullWidth
					variant="contained"
					color="primary"
					onClick={this.handleSubmit}>
					Done
				</Button>
			</Container>
		);
	}
}

const mapStateToProps = ({connections, authentication}) => {
	return {connections, authentication};
};


export const RejectConnectionComponent = connect(mapStateToProps)(_RejectConnectionComponent);

