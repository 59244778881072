import React, {Component} from 'react';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {registrationActions} from "../../redux/actions/registration.actions";
import connect from "react-redux/lib/connect/connect";

class _ContactForm extends Component {
	state = {
		name:    '',
		email:   '',
		message: ''
	};

	handleChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({submitted: true});
		const {dispatch} = this.props;

		const {name, email, message} = this.state,
		      formData               = {
			      name,
			      email,
			      message
		      };

		dispatch(registrationActions.submitContactForm(formData));

		return false;
	};

	handleClose = (e) => {
		const {dispatch} = this.props;

		dispatch(registrationActions.closeContactPopup());
	};

	render() {
		const {name, email, message} = this.state;

		return (
			<Container component="main" maxWidth="sm">
				<form noValidate onSubmit={this.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								autoComplete="name"
								name="name"
								variant="outlined"
								required
								fullWidth
								id="name"
								label="Your Name"
								autoFocus
								onChange={this.handleChange}
								inputProps={{maxLength: 255}}
								value={name}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="email"
								label="Your E-Mail"
								name="email"
								autoComplete="email"
								onChange={this.handleChange}
								inputProps={{maxLength: 255}}
								value={email}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Message (Max. 1000 chars)"
								multiline
								required
								rowsMax="7"
								name="message"
								value={message}
								onChange={this.handleChange}
								margin="normal"
								variant="filled"
								fullWidth={true}
								inputProps={{maxLength: 1000}}
								rows={7}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary">
						Send
					</Button>
				</form>
			</Container>
		);
	}
}

const mapStateToProps = ({registration}) => registration;

export const ContactForm = connect(mapStateToProps)(_ContactForm);
