import React from 'react';
import './connection-list.component.css';
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import connect from "react-redux/lib/connect/connect";
import {connectionsActions} from "../../redux/actions/connections.actions";
import DataGrid, {Column, Editing, Paging, Lookup, Form, Pager, HeaderFilter, Export} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import {ConnectionService} from "../../services/connection.service";
import {CONNECTION_STATUS} from "../../config/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {RejectConnectionComponent} from "./reject-connection.component";
import CheckBox from "devextreme-react/ui/check-box";
import {assign} from 'lodash';
import Grid from "@material-ui/core/Grid";

const WebsiteTemplate = ({data: {value}}) => {
	if (!value.match(/^[a-zA-Z]+:\/\//))
	{
		value = 'http://' + value;
	}

	return value ? <a href={value} target="_blank" rel="noopener noreferrer">Link</a> : '--';
};

const EmailTemplate = ({data: {value, data: {verificationToken}}}) => {
	return value ? (
		<div title={value}>
			<a href={`mailto:${value}`}>E-Mail</a>
			{!!verificationToken && <div><strong className={'text-red'}>UNVERIFIED</strong></div>}
		</div>
	) : '--';
};

const StatusTemplate = ({data: {value}}) => {
	switch (value) {
		case CONNECTION_STATUS.REJECTED:
			return 'Rejected';
		case CONNECTION_STATUS.AWAITING_APPROVAL:
			return 'Pending Approval';
		case CONNECTION_STATUS.APPROVED:
			return 'Approved';
		default:
			return '--';
	}
};

const SpecialtyTemplate = ({data: {value}}) => {
	return <CheckBox value={value === 'Yes'} readOnly/>
};

const GridLegend = () => (
	<div className="gridLegendContainer">
		<Grid container>
			<Grid item xs={1} className="gridLegendCell">
				<div className="gridLegendColorBox notapproved"/>
			</Grid>
			<Grid item xs={11} className="gridLegendCell">
				Awaiting initial approval
			</Grid>

			<Grid item xs={1} className="gridLegendCell">
				<div className="gridLegendColorBox notapproved-edit"/>
			</Grid>
			<Grid item xs={11} className="gridLegendCell">
				Awaiting edit approval
			</Grid>
		</Grid>
	</div>
);

class _ConnectionListComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			columns:          [],
			columnExtensions: [],
			loading:          false
		}
	}

	componentDidMount() {
		this.getConnections();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {authentication: {loggedIn: oldLoggedIn}} = prevProps,
		      {authentication: {loggedIn}}              = this.props;

		if (!oldLoggedIn && loggedIn) {
			this.getConnections();
		}
	}

	getConnections = (successfulResponse) => {
		if (successfulResponse === false) return;

		const {loggedIn} = this.props.authentication;

		this.props.dispatch(connectionsActions.getConnections(!!loggedIn));
	};

	handleModifyRowResponse = (response) => {
		return !response.success;
	};

	onRowPrepared = event => {
		if (event.data) {
			if (event.data.status === CONNECTION_STATUS.AWAITING_APPROVAL) {
				event.rowElement.classList.add('notapproved');
			}

			if (event.data.status === CONNECTION_STATUS.AWAITING_EDIT_APPROVAL) {
				event.rowElement.classList.add('notapproved-edit');
			}
		}
	};

	onRowUpdated = event => {
		event.cancel = ConnectionService.updateConnection(assign({}, event.oldData, event.newData))
			.then(this.handleModifyRowResponse)
			.then(this.getConnections);
	};

	onRowRemoved = event => {
		event.cancel = ConnectionService.removeConnection(event.data.connectionID)
			.then(this.handleModifyRowResponse);
	};

	onRowInserted = event => {
		event.cancel = ConnectionService.addConnection(event.data)
			.then(this.handleModifyRowResponse)
			.then(this.getConnections);
	};

	onInitNewRow = event => {
		event.data = {
			immunology:   false,
			neuroscience: false,
			clinical:     false,
			status:       CONNECTION_STATUS.APPROVED
		}
	};

	onApprove = event => {
		event.cancel = ConnectionService.updateConnection(assign({}, event.row.data, {
			status: CONNECTION_STATUS.APPROVED
		}))
			.then(response => {
				if (!response.success) {
					return true;
				}

				this.getConnections();

				return false;
			});
	};

	onReject = event => {
		this.props.dispatch(connectionsActions.openRejectionPopup(assign({}, event.row.data)));
	};

	onResendVerificationEmail = event => {
		ConnectionService.resendVerificationEmail(event.row.data.connectionID);
	};

	approveRejectButtonVisible = event => {
		return event.row.data && (
			event.row.data.status === CONNECTION_STATUS.AWAITING_APPROVAL ||
			event.row.data.status === CONNECTION_STATUS.AWAITING_EDIT_APPROVAL
		);
	};

	resendVerificationEmailButtonVisible = event => {
		return event.row.data && event.row.data.status === CONNECTION_STATUS.AWAITING_APPROVAL && event.row.data.verificationToken;
	};

	handleRejectionCancel = event => {
		this.props.dispatch(connectionsActions.closeRejectionPopup());
	};

	calculateSpecialtyCellValue(field) {
		return function(rowData) {
			return !!rowData[field] ? 'Yes' : 'No';
		}
	}

	render() {
		const {connections: {connections, rejectionPopupOpen}, authentication: {loggedIn, checkedSession}} = this.props;

		return checkedSession && (
			<>
				<Paper className="connectionListContainer">
					<Box padding={2}>
						{loggedIn && <GridLegend/>}

						<DataGrid dataSource={connections}
						          keyExpr={'connectionID'}
						          showBorders={true}
						          onInitNewRow={this.onInitNewRow}
						          onRowInserting={this.onRowInserted}
						          onRowUpdating={this.onRowUpdated}
						          onRowRemoving={this.onRowRemoved}
						          onRowPrepared={this.onRowPrepared}
						          wordWrapEnabled={true}>

							<Paging enabled={true} defaultPageSize={50}/>
							<Pager visible={true} showPageSizeSelector={true}/>
							{loggedIn && <Export enabled={true} fileName={'pnirs_ap_export'}/>}
							<Editing mode={'popup'}
							         allowUpdating={loggedIn}
							         allowDeleting={loggedIn}
							         allowAdding={loggedIn}>
								<Form>
									<Item itemType={'group'} caption={'Personal Information'}>
										<Item dataField={'firstName'}/>
										<Item dataField={'lastName'}/>
										<Item dataField={'country'}/>
										<Item dataField={'email'}/>
										<Item dataField={'website'}/>
									</Item>
									<Item itemType={'group'} caption={'Research Specializations'} colCount={3}>
										<Item dataField={'immunology'}/>
										<Item dataField={'neuroscience'}/>
										<Item dataField={'clinical'}/>
									</Item>
								</Form>
							</Editing>
							<HeaderFilter visible={true} allowSearch={true}/>

							<Column dataField={'firstName'} allowHeaderFiltering={false} visible={false}/>
							<Column dataField={'lastName'} allowHeaderFiltering={false} visible={false}/>
							<Column dataField={'name'} minWidth={90} defaultSortIndex={2} defaultSortOrder={'asc'}
							        allowHeaderFiltering={false}/>
							<Column dataField={'country'} minWidth={100} defaultSortIndex={1} defaultSortOrder={'asc'}
							        allowHeaderFiltering={true}/>
							<Column dataField={'website'} cellComponent={WebsiteTemplate} minWidth={75}
							        allowHeaderFiltering={false}/>
							<Column dataField={'email'} caption={'E-Mail'} cellComponent={EmailTemplate}
							        minWidth={65}
							        allowHeaderFiltering={false}/>
							<Column dataField={'immunology'} dataType={'boolean'} minWidth={100}
							        calculateCellValue={this.calculateSpecialtyCellValue('immunology')}
							        cellComponent={SpecialtyTemplate}/>
							<Column dataField={'neuroscience'} dataType={'boolean'} minWidth={100}
							        calculateCellValue={this.calculateSpecialtyCellValue('neuroscience')}
							        cellComponent={SpecialtyTemplate}/>
							<Column dataField={'clinical'} dataType={'boolean'} minWidth={100}
							        calculateCellValue={this.calculateSpecialtyCellValue('clinical')}
							        cellComponent={SpecialtyTemplate}/>
							{
								loggedIn &&
								<Column dataField={'status'} dataType={'string'} width={125} defaultSortIndex={0}
								        visible={false}
								        defaultSortOrder={'asc'} cellComponent={StatusTemplate}/>
							}
							<Column type={'buttons'} buttons={['edit', 'delete', {
								hint:    'Approve',
								icon:    'check',
								visible: this.approveRejectButtonVisible,
								onClick: this.onApprove
							}, {
								hint:    'Reject',
								icon:    'close',
								visible: this.approveRejectButtonVisible,
								onClick: this.onReject
							}, {
								hint:    'Resend Verification E-mail',
								icon:    'repeat',
								visible: this.resendVerificationEmailButtonVisible,
								onClick: this.onResendVerificationEmail
							}]}>
							</Column>
						</DataGrid>
					</Box>
				</Paper>

				<Dialog open={rejectionPopupOpen} onClose={this.handleRejectionCancel}
				        aria-labelledby="form-dialog-title">
					<DialogTitle id="admin-login-title">Reject Registration</DialogTitle>
					<DialogContent>
						<RejectConnectionComponent/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleRejectionCancel} color="primary">
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

const mapStateToProps = (state) => state;

export const ConnectionListComponent = connect(mapStateToProps)(_ConnectionListComponent);

