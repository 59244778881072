import {ConnectionService} from "../../services/connection.service";
import {connectionsConstants, userConstants} from "../../config/constants";

export const connectionsActions = {
	getConnections,
	openRejectionPopup,
	closeRejectionPopup
};

function getConnections(admin = false) {
	return dispatch => ConnectionService.getConnections(admin)
		.then(connections => {
			dispatch(success(connections))
		});

	function success(connections) {
		return {type: connectionsConstants.RECEIVE_CONNECTIONS, connections}
	}
}

function openRejectionPopup(rejectingConnection) {
	return {
		type: connectionsConstants.OPEN_REJECTION_POPUP,
		rejectingConnection
	}
}

function closeRejectionPopup() {
	return {
		type: connectionsConstants.CLOSE_REJECTION_POPUP
	}
}
