import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import connect from "react-redux/lib/connect/connect";
import {registrationActions} from "../../redux/actions/registration.actions";
import {editMyInfoActions} from "../../redux/actions/edit-my-info.actions";

export class _EditMyInfoForm extends Component {
	state = {
		firstName:    '',
		lastName:     '',
		country:      '',
		website:      '',
		email:        '',
		immunology:   false,
		neuroscience: false,
		clinical:     false
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		console.log(this.props);

		const {firstName, lastName, country, website, email, immunology, neuroscience, clinical} = this.props.editingConnection;

		this.setState({
			firstName:    firstName || '',
			lastName:     lastName || '',
			country:      country || '',
			website:      website || '',
			email:        email || '',
			immunology:   !!immunology,
			neuroscience: !!neuroscience,
			clinical:     !!clinical
		});
	}

	handleChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value});
	};

	handleCheckboxChange = (e) => {
		const {value, checked} = e.target;
		this.setState({[value]: !!checked});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({submitted: true});
		const {dispatch, editRequestToken} = this.props;

		const {firstName, lastName, country, website, email, immunology, neuroscience, clinical} = this.state,
		      formData                                                                           = {
			      editRequestToken,
			      firstName,
			      lastName,
			      country,
			      website,
			      email,
			      immunology,
			      neuroscience,
			      clinical
		      };

		dispatch(editMyInfoActions.submitEditMyInfoForm(formData));

		return false;
	};

	handleClose = (e) => {
		const {dispatch} = this.props;

		dispatch(editMyInfoActions.closeEditMyInfoPopup());
	};

	render() {
		const {firstName, lastName, country, website, email, immunology, neuroscience, clinical} = this.state;
		const {editSubmitted}                                                                    = this.props;

		if (!!editSubmitted) {
			return (
				<Container component="main" maxWidth="md">
					<p>Thank you for submitting your changes. Your request requires approval.
						You will receive an e-mail once your request has been approved, after which your information in
						the table will be updated with your changes.</p>

					<Button
						type="button"
						fullWidth
						variant="contained"
						color="primary"
						onClick={this.handleClose}>
						Close
					</Button>
				</Container>
			);
		}

		return (
			<div className={'registerContainer'}>
				<Container component="main" maxWidth="md">
					<p>Please fill out the form below to edit your contact information. All fields are required. Note that if you change your e-mail address, you
					will be required to verify your new e-mail address before your new information can be displayed.</p>

					<p style={{display: 'block', marginBottom: '25px'}}>For your website, you may link to any page
						related to your work as a scientist, but a link to a lab website, ResearchGate, ORCID, or public
						Facebook or Twitter is preferred. Please click the
						"Contact Administrator" link at the bottom of the main page if you have any questions.</p>
					<form noValidate onSubmit={this.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="fname"
									name="firstName"
									variant="outlined"
									required
									fullWidth
									id="firstName"
									label="First Name"
									autoFocus
									onChange={this.handleChange}
									inputProps={{maxLength: 255}}
									value={firstName}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									autoComplete="lname"
									onChange={this.handleChange}
									inputProps={{maxLength: 255}}
									value={lastName}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Country"
									name="country"
									autoComplete="country"
									onChange={this.handleChange}
									inputProps={{maxLength: 50}}
									value={country}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="website"
									label="Website"
									name="website"
									autoComplete="website"
									onChange={this.handleChange}
									inputProps={{maxLength: 1000}}
									value={website}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									onChange={this.handleChange}
									inputProps={{maxLength: 255}}
									value={email}
								/>
							</Grid>
							<Grid item xs={12} className={'checkboxItem'}>
								<FormControlLabel
									control={<Checkbox value="immunology" color="primary"
									                   onChange={this.handleCheckboxChange}
									                   checked={immunology}/>}
									label="Immunology"
								/>
							</Grid>
							<Grid item xs={12} className={'checkboxItem'}>
								<FormControlLabel
									control={<Checkbox value="neuroscience" color="primary"
									                   onChange={this.handleCheckboxChange}
									                   checked={neuroscience}/>}
									label="Neuroscience"
								/>
							</Grid>
							<Grid item xs={12} className={'checkboxItem'}>
								<FormControlLabel
									control={<Checkbox value="clinical" color="primary"
									                   onChange={this.handleCheckboxChange}
									                   checked={clinical}/>}
									label="Clinical"
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary">
							Submit Changes
						</Button>
					</form>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = ({editMyInfo}) => editMyInfo;

export const EditMyInfoForm = connect(mapStateToProps)(_EditMyInfoForm);
