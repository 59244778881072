import HttpService from '../services/http.service';
import notify from "devextreme/ui/notify";

export const AuthenticationService = {
	validateToken,
	login,
	logout,
	requestPasswordChange,
	changePassword
};

function validateToken() {
	return HttpService.get(`/auth/session`, true)
		.then(async response => {
			if (!response.ok) {
				localStorage.removeItem('token');
			}

			return response;
		});
}

function login(username, password) {
	return HttpService.post(`/auth/login`, {username, password}, true)
		.then(async response => {
			const body = await response.json();

			if (!response.ok) {
				if (response.status === 403 || response.status === 401) {
					return Promise.reject('Invalid username and/or password');
				}

				const error = (body && body.message) || response.statusText;
				return Promise.reject(error);
			}

			return body;
		})
		.then(response => {
			// store jwt token in local storage to keep user logged in between page refreshes
			localStorage.setItem('token', response.token);

			return response;
		});
}

function logout() {
	localStorage.removeItem('token');
}

function requestPasswordChange(email) {
	return HttpService.post(`/auth/requestPasswordChange`, {email}, true)
		.then(async response => {
			const body = await response.json();

			if (!response.ok) {
				if (response.status === 401) {
					return Promise.reject('You need to enter a valid e-mail address.');
				}

				const error = (body && body.message) || response.statusText;
				return Promise.reject(error);
			} else if (body.success) {
				if (body.message) {
					notify({
						message:  body.message,
						position: {
							at:     'top center',
							offset: '0 60'
						}
					}, 'success', 10000);
				}
			}

			return body;
		});
}

function changePassword(newPassword, oldPassword, pwChangeToken) {
	const pwChangeMethod = pwChangeToken ? 'changePasswordWithToken' : 'changePassword';

	return HttpService.post(`/auth/${pwChangeMethod}`, {newPassword, oldPassword, pwChangeToken}, true)
		.then(async response => {
			const body = await response.json();

			if (!response.ok) {
				if (response.status === 401) {
					return Promise.reject('The old password you entered is incorrect.');
				}

				if (response.status === 403) {
					return Promise.reject(`You can't do that right now.`);
				}

				if (response.status === 404) {
					return Promise.reject(`That password reset token is expired or has already been used.`);
				}

				if (response.status === 409) {
					return Promise.reject(`You can't reuse the same password again.`);
				}

				if (response.status === 422) {
					return Promise.reject(`Password does not meet minimum requirements.`);
				}

				const error = (body && body.message) || response.statusText;
				return Promise.reject(error);
			}

			return body;
		});
}
