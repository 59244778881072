import React, {Component} from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import connect from "react-redux/lib/connect/connect";
import {editMyInfoActions} from "../../redux/actions/edit-my-info.actions";

class _EditMyInfoRequest extends Component {
	state = {
		email: ''
	};

	handleChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const {dispatch} = this.props;

		const {email}  = this.state,
		      formData = {
			      email
		      };

		dispatch(editMyInfoActions.submitEditMyInfoRequest(formData));

		return false;
	};

	handleClose = (e) => {
		const {dispatch} = this.props;

		dispatch(editMyInfoActions.closeEditMyInfoRequest());
	};

	render() {
		const {email}               = this.state;
		const {editRequestAccepted} = this.props;

		if (editRequestAccepted) {
			return (
				<>
					<p>If a matching record exists, you will receive an e-mail at the address you entered. Please follow
						the instructions in that e-mail in order to
						complete the process of editing your registration information.</p>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={this.handleClose}>
						Close
					</Button>
				</>
			)
		}

		return (
			<>
				<p>Enter your e-mail address below and you will be sent an e-mail with a unique link that you may click
					to edit
					your registration information. Your changes will require administrator approval, which could take up
					to 5 business days.</p>

				<Container component="main" maxWidth="sm">
					<form noValidate onSubmit={this.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Your E-Mail Address"
									name="email"
									autoComplete="email"
									onChange={this.handleChange}
									inputProps={{maxLength: 255}}
									value={email}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary">
									Submit Request
								</Button>
							</Grid>
						</Grid>
					</form>
				</Container>
			</>
		);
	}
}

const mapStateToProps = ({editMyInfo}) => editMyInfo;

export const EditMyInfoRequest = connect(mapStateToProps)(_EditMyInfoRequest);
