import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {isEmpty} from 'lodash';
import Container from '@material-ui/core/Container';
import {authenticationActions} from "../../redux/actions/authentication.actions";
import connect from "react-redux/lib/connect/connect";
import './login.component.css';

class _ChangePasswordForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			oldPassword:     '',
			newPassword:     '',
			passwordConfirm: ''
		};
	}

	handleChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({submitted: true});
		const {oldPassword, newPassword, passwordConfirm} = this.state;
		const {dispatch, pwChangeToken}                   = this.props;

		if (isEmpty(oldPassword) && !pwChangeToken) {
			dispatch(authenticationActions.changePwError('Please type in your old password.'));
		} else if (newPassword !== passwordConfirm) {
			dispatch(authenticationActions.changePwError('Passwords do not match.'));
		} else if (!isEmpty(newPassword)) {
			dispatch(authenticationActions.changePassword(newPassword, oldPassword, pwChangeToken));
		}

		return false;
	};

	render() {
		const {user, pwChangeError, pwChangeToken}     = this.props;
		const {password, newPassword, passwordConfirm} = this.state;

		if (!pwChangeToken && !user.requirePwChange) return 'Cannot change password at this time.';

		return (
			<Container component="main" maxWidth="xs">
				{user.requirePwChange &&
				 <p className={'text-red'}>You are required to change your password by an administrator.</p>}
				<p>New password must be at least 8 characters and must contain at least (1) number and (1) upper-case
					character.</p>
				<form noValidate autoComplete={"off"} onSubmit={this.handleSubmit}>
					{!pwChangeToken && (
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="oldPassword"
							label="Old Password"
							type="password"
							id="oldPassword"
							value={password}
							onChange={this.handleChange}
							autoComplete={"off"}
						/>
					)}
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="newPassword"
						label="New Password"
						type="password"
						id="newPassword"
						value={newPassword}
						onChange={this.handleChange}
						autoComplete={"off"}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="passwordConfirm"
						label="Confirm Password"
						type="password"
						id="passwordConfirm"
						value={passwordConfirm}
						onChange={this.handleChange}
						autoComplete={"off"}
					/>
					<div className="text-red">
						{pwChangeError}
					</div>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary">
						Submit
					</Button>
				</form>
			</Container>
		);
	}
}

const mapStateToProps = ({authentication}) => authentication;

export const ChangePasswordForm = connect(mapStateToProps)(_ChangePasswordForm);
