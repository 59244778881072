import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {authenticationActions} from "../../redux/actions/authentication.actions";
import connect from "react-redux/lib/connect/connect";
import './login.component.css';

class _LoginForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email:     '',
			password:  '',
			submitted: false
		};
	}

	handleChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({submitted: true});
		const {email, password} = this.state,
		      {dispatch}        = this.props;

		if (email && password) {
			dispatch(authenticationActions.login(email, password));
		}

		return false;
	};

	handleForgotPasswordClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const {email}    = this.state,
		      {dispatch} = this.props;

		if (email) {
			dispatch(authenticationActions.requestPasswordChange(email));
		}

		return false;
	};

	render() {
		const {loggedIn, loggingIn, loginError} = this.props;
		const {email, password, submitted}      = this.state;

		return (
			<Container component="main" maxWidth="xs">
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<form noValidate onSubmit={this.handleSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						value={email}
						onChange={this.handleChange}
						autoFocus
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						value={password}
						onChange={this.handleChange}
						autoComplete="current-password"
					/>
					<div className="text-red">
						{loginError}
					</div>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary">
						Sign In
					</Button>
					<div className="forgot-password-box">
						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2" onClick={this.handleForgotPasswordClick}>
									Forgot password?
								</Link>
							</Grid>
						</Grid>
					</div>
				</form>
			</Container>
		);
	}
}

const mapStateToProps = ({authentication}) => authentication;

export const LoginForm = connect(mapStateToProps)(_LoginForm);
