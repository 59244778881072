import {registrationConstants} from "../../config/constants";

const initialState = {
	registerPopupOpen:     false,
	registered:            false,
	verifiedEmail:         false,
	contactPopupOpen:      false
};

export function registration(state = initialState, action) {
	switch (action.type) {
		case registrationConstants.OPEN_REGISTER:
			return {
				...state,
				registerPopupOpen: true
			};
		case registrationConstants.CLOSE_REGISTER:
			return {
				...state,
				registerPopupOpen: false
			};
		case registrationConstants.REGISTRATION_ACCEPTED:
			return {
				...state,
				registered: true
			};
		case registrationConstants.REGISTRATION_ERROR:
			return {
				...state,
				registered: false
			};
		case registrationConstants.EMAIL_VERIFIED:
			window.history.replaceState({}, document.title, '/');

			return {
				...state,
				verifiedEmail: true
			};
		case registrationConstants.EMAIL_VERIFY_ERROR:
			window.history.replaceState({}, document.title, '/');

			return {
				...state,
				emailVerifyError: action.errorMessage
			};
		case registrationConstants.OPEN_CONTACT_POPUP:
			return {
				...state,
				contactPopupOpen: true
			};
		case registrationConstants.CLOSE_CONTACT_POPUP:
			return {
				...state,
				contactPopupOpen: false
			};
		default:
			return state;
	}
}
