import React from 'react';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {LoginForm} from "../login/login.component";
import AddIcon from '@material-ui/icons/Add';
import connect from "react-redux/lib/connect/connect";
import {authenticationActions} from "../../redux/actions/authentication.actions";
import './home-page.component.css';
import {isEmpty} from 'lodash';
import {ConnectionListComponent} from "../connection-list/connection-list.component";
import {RegisterForm} from "./register.component";
import {registrationActions} from "../../redux/actions/registration.actions";
import {Card} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import {ChangePasswordForm} from "../login/changePassword.component";
import {IS_DEV} from "../../config/app.config";
import Grid from "@material-ui/core/Grid";
import {ContactForm} from "./contact-form.component";
import {EditMyInfoRequest} from "./edit-my-info-request.component";
import {editMyInfoActions} from "../../redux/actions/edit-my-info.actions";
import {EditMyInfoForm} from "./edit-my-info.component";

const emailVerifiedCard = () => (
	<Card className={'emailVerifiedCard'}>
		<CardContent>
			<Typography variant={'h6'} component={'h2'}>
				Verification Successful
			</Typography>
			<Typography component={'p'}>
				Your e-mail address has been verified successfully. If your registration is approved, you will receive
				an e-mail notification and your entry will appear in
				the below list within 3-5 business days. If your registration is rejected, you will receive an e-mail
				with further details.
			</Typography>
		</CardContent>
	</Card>
);

class _HomePage extends React.Component {
	constructor(props) {
		super(props);

		let token = localStorage.getItem('token');

		if (token) {
			this.props.dispatch(authenticationActions.validateToken(token));
		} else {
			this.props.dispatch(authenticationActions.checkedSessionNoLogin());
		}
	}

	componentDidMount() {
		let urlParams = new URLSearchParams(window.location.search);

		if (urlParams.has('verifyToken')) {
			this.props.dispatch(registrationActions.verifyEmail(urlParams.get('verifyToken')));
		}

		if (urlParams.has('pwChangeToken')) {
			this.props.dispatch(authenticationActions.openChangePwPopup(urlParams.get('pwChangeToken')));
		}

		if (urlParams.has('editToken')) {
			this.props.dispatch(editMyInfoActions.verifyEditMyInfoToken(urlParams.get('editToken')));
		}
	}

	handleLogout = () => {
		this.props.dispatch(authenticationActions.logout());
	};

	handleLoginOpen = () => {
		this.props.dispatch(authenticationActions.openLoginPopup());
	};

	handleLoginClose = () => {
		this.props.dispatch(authenticationActions.closeLoginPopup());
	};

	handleRegisterOpen = () => {
		this.props.dispatch(registrationActions.openRegisterPopup());
	};

	handleRegisterClose = () => {
		this.props.dispatch(registrationActions.closeRegisterPopup());
	};

	handleChangePwClose = () => {
		this.props.dispatch(authenticationActions.closeChangePwPopup());
	};

	handleContactOpen = () => {
		this.props.dispatch(registrationActions.openContactPopup());
	};

	handleContactClose = () => {
		this.props.dispatch(registrationActions.closeContactPopup());
	};

	handleEditMyInfoRequestOpen = () => {
		this.props.dispatch(editMyInfoActions.openEditMyInfoRequest());
	};

	handleEditMyInfoRequestClose = () => {
		this.props.dispatch(editMyInfoActions.closeEditMyInfoRequest());
	};

	handleEditMyInfoClose = () => {
		this.props.dispatch(editMyInfoActions.closeEditMyInfoPopup());
	};

	getLoginMessage(user) {
		if (!isEmpty(user)) {
			return (
				<span>
					Welcome, {user.email} | <a href="/#" onClick={this.handleLogout}>Logout</a>
				</span>
			)
		}

		return (
			<>
				<h3><a href="/#" onClick={this.handleEditMyInfoRequestOpen}>Edit My Information</a></h3>
				<h3><a href="/#" onClick={this.handleContactOpen}>Contact Administrator</a></h3>
				<small><a href="/#" onClick={this.handleLoginOpen}>Admin login</a></small>
			</>
		)
	}

	pageShouldDisplay() {
		const {authentication: {user, checkedSession}} = this.props;

		return isEmpty(user) || (checkedSession && !user.requirePwChange);
	}

	render() {
		const {
			      authentication: {user, loginPopupOpen, pwChangeOpen},
			      registration:   {registerPopupOpen, verifiedEmail, contactPopupOpen},
			      editMyInfo:     {editMyInfoRequestOpen, editMyInfoPopupOpen}
		      } = this.props;

		return <>
			<AppBar position="static" color="default">
				<Toolbar>
					<Grid container>
						<Grid item xs={12} md={3} xl={2}>
							<img src="/img/logo_white.gif" alt="PNIRS logo"/>
						</Grid>
						<Grid item xs={12} md={9} xl={10}>
							<h1><span className="asia-pacific">Asia-Pacific</span> Global Research Connections</h1>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			{IS_DEV && <div className={'devBanner'}>DEVELOPMENT MODE</div>}

			{this.pageShouldDisplay() && (
				<>
					<main>
						<Container maxWidth={'xl'}>
							{verifiedEmail && emailVerifiedCard()}

							<Button
								variant="contained"
								color="secondary"
								startIcon={<AddIcon/>}
								className="register-button"
								onClick={this.handleRegisterOpen}
							>Register</Button>

							<ConnectionListComponent/>
						</Container>
					</main>
					<footer>
						{this.getLoginMessage(user)}
					</footer>
				</>
			)}

			<Dialog open={loginPopupOpen} onClose={this.handleLoginClose} aria-labelledby="admin-login-title">
				<DialogTitle id="admin-login-title">Admin Login</DialogTitle>
				<DialogContent>
					<LoginForm/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleLoginClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={pwChangeOpen} disableBackdropClick={!!user.requirePwChange} onClose={this.handleChangePwClose}
			        aria-labelledby="change-pw-title">
				<DialogTitle id="change-pw-title">Change Password</DialogTitle>
				<DialogContent>
					<ChangePasswordForm/>
				</DialogContent>

				{!user.requirePwChange && (
					<DialogActions>
						<Button onClick={this.handleChangePwClose} color="primary">
							Cancel
						</Button>
					</DialogActions>
				)}
			</Dialog>

			<Dialog open={registerPopupOpen} onClose={this.handleRegisterClose}
			        aria-labelledby="registration-form-title">
				<DialogTitle id="registration-form-title">Registration Form</DialogTitle>
				<DialogContent>
					<RegisterForm/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleRegisterClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={contactPopupOpen} onClose={this.handleContactClose} aria-labelledby="contact-form-title">
				<DialogTitle id="contact-form-title">Contact Administrator</DialogTitle>
				<DialogContent>
					<ContactForm/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleContactClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={editMyInfoRequestOpen} onClose={this.handleEditMyInfoRequestClose}
			        aria-labelledby="edit-request-form-title">
				<DialogTitle id="edit-request-form-title">Request to Edit My Information</DialogTitle>
				<DialogContent>
					<EditMyInfoRequest/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleEditMyInfoRequestClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={editMyInfoPopupOpen} onClose={this.handleEditMyInfoClose}
			        aria-labelledby="edit-request-form-title" maxWidth={'md'}>
				<DialogTitle id="edit-request-form-title">Edit My Information</DialogTitle>
				<DialogContent>
					<EditMyInfoForm/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleEditMyInfoClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	}
}

const mapStateToProps = ({registration, authentication, editMyInfo}) => {
	return {registration, authentication, editMyInfo};
};

export const HomePage = connect(mapStateToProps)(_HomePage);
