import {sortBy} from 'lodash';
import HttpService from '../services/http.service';
import notify from "devextreme/ui/notify";

export const ConnectionService = {
	getConnections,
	registerConnection,
	verifyRegistrationEmail,
	addConnection,
	updateConnection,
	removeConnection,
	resendVerificationEmail,
	verifyEditMyInfoToken,
	submitEditMyInfoRequest,
	submitEditMyInfoForm
};

function getConnections(admin = false) {
	return HttpService.get(`/connection/${admin ? 'admin' : 'public'}`)
		.then(response => {
			if (response.success) {
				return sortBy(response.results, ['status', 'name']);
			}

			return [];
		});
}

function registerConnection(connection) {
	return HttpService.post(`/connection/register`, connection, true);
}

function verifyRegistrationEmail(token) {
	return HttpService.post('/connection/verify', {token}, true);
}

function addConnection(connection) {
	return HttpService.post(`/connection`, connection);
}

function updateConnection(connection) {
	return HttpService.patch(`/connection/${connection.connectionID}`, connection);
}

function removeConnection(connectionID) {
	return HttpService.delete(`/connection/${connectionID}`);
}

function resendVerificationEmail(connectionID) {
	return HttpService.post(`/connection/resendVerification`, {
		connectionID
	});
}

function verifyEditMyInfoToken(editToken) {
	return HttpService.get(`/connection/verifyEditMyInfoToken?editToken=${editToken}`, true);
}

function submitEditMyInfoRequest(formData) {
	return HttpService.post('/connection/editMyInfoRequest', formData, true)
		.then(async response => {
			const clone = response.clone(),
			      body  = await response.json();

			if (!response.ok) {
				const error = (body && body.message) || response.statusText;
				return Promise.reject(error);
			} else if (body.success) {
				if (body.message) {
					notify({
						message:  body.message,
						position: {
							at:     'top center',
							offset: '0 60'
						}
					}, 'success', 10000);
				}
			}

			return clone;
		});
}

function submitEditMyInfoForm(formData) {
	return HttpService.post(`/connection/editMyInfo`, formData, true);
}
