import {combineReducers} from "redux";
import {authentication} from "./authentication.reducer";
import {connections} from "./connections.reducer";
import {registration} from "./registration.reducer";
import {editMyInfo} from "./edit-my-info.reducer";

const rootReducer = combineReducers({
	connections,
	authentication,
	registration,
	editMyInfo
});

export default rootReducer;
