import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import connect from "react-redux/lib/connect/connect";
import {registrationActions} from "../../redux/actions/registration.actions";

export class _RegisterForm extends Component {
	state = {
		firstName:    '',
		lastName:     '',
		country:      '',
		website:      '',
		email:        '',
		immunology:   false,
		neuroscience: false,
		clinical:     false
	};

	constructor(props) {
		super(props);
	}

	handleChange = (e) => {
		const {name, value} = e.target;
		this.setState({[name]: value});
	};

	handleCheckboxChange = (e) => {
		const {value, checked} = e.target;
		this.setState({[value]: !!checked});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({submitted: true});
		const {dispatch} = this.props;

		const {firstName, lastName, country, website, email, immunology, neuroscience, clinical} = this.state,
		      registrationData                                                                   = {
			      firstName,
			      lastName,
			      country,
			      website,
			      email,
			      immunology,
			      neuroscience,
			      clinical
		      };

		dispatch(registrationActions.submitRegistrationForm(registrationData));

		return false;
	};

	handleClose = (e) => {
		const {dispatch} = this.props;

		dispatch(registrationActions.closeRegisterPopup());
	};

	render() {
		const {firstName, lastName, country, website, email, immunology, neuroscience, clinical} = this.state;
		const {registered}                                                                       = this.props;

		if (!!registered) {
			return (
				<Container component="main" maxWidth="sm">
					<p>Thank you for registering. Your request requires validation.
						You will receive an e-mail shortly containing a link that you must click in order for your
						registration request to move to the next step.</p>

					<Button
						type="button"
						fullWidth
						variant="contained"
						color="primary"
						onClick={this.handleClose}>
						Close
					</Button>
				</Container>
			);
		}

		return (
			<div className={'registerContainer'}>
				<Container component="main" maxWidth="sm">
					<p>Please fill out the form below to register your contact information. All fields are required.</p>

					<p style={{display: 'block', marginBottom: '25px'}}>For your website, you may link to any page
						related to your work as a scientist, but a link to a lab website, ResearchGate, ORCID, or public Facebook or Twitter is preferred. Please click the
						"Contact Administrator" link at the bottom of the main page if you have any questions.</p>
					<form noValidate onSubmit={this.handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="fname"
									name="firstName"
									variant="outlined"
									required
									fullWidth
									id="firstName"
									label="First Name"
									autoFocus
									onChange={this.handleChange}
									inputProps={{maxLength: 255}}
									value={firstName}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									autoComplete="lname"
									onChange={this.handleChange}
									inputProps={{maxLength: 255}}
									value={lastName}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Country"
									name="country"
									autoComplete="country"
									onChange={this.handleChange}
									inputProps={{maxLength: 50}}
									value={country}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="website"
									label="Website"
									name="website"
									autoComplete="website"
									onChange={this.handleChange}
									inputProps={{maxLength: 1000}}
									value={website}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									onChange={this.handleChange}
									inputProps={{maxLength: 255}}
									value={email}
								/>
							</Grid>
							<Grid item xs={12} className={'checkboxItem'}>
								<FormControlLabel
									control={<Checkbox value="immunology" color="primary"
									                   onChange={this.handleCheckboxChange}
									                   checked={immunology}/>}
									label="Immunology"
								/>
							</Grid>
							<Grid item xs={12} className={'checkboxItem'}>
								<FormControlLabel
									control={<Checkbox value="neuroscience" color="primary"
									                   onChange={this.handleCheckboxChange}
									                   checked={neuroscience}/>}
									label="Neuroscience"
								/>
							</Grid>
							<Grid item xs={12} className={'checkboxItem'}>
								<FormControlLabel
									control={<Checkbox value="clinical" color="primary"
									                   onChange={this.handleCheckboxChange}
									                   checked={clinical}/>}
									label="Clinical"
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary">
							Submit Registration
						</Button>
					</form>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = ({registration}) => registration;

export const RegisterForm = connect(mapStateToProps)(_RegisterForm);
