import {registrationConstants} from "../../config/constants";
import {ConnectionService} from "../../services/connection.service";
import {ContactService} from "../../services/contact.service";

export const registrationActions = {
	openRegisterPopup,
	closeRegisterPopup,
	submitRegistrationForm,
	verifyEmail,
	openContactPopup,
	closeContactPopup,
	submitContactForm
};

function openRegisterPopup() {
	return {type: registrationConstants.OPEN_REGISTER};
}

function closeRegisterPopup() {
	return {type: registrationConstants.CLOSE_REGISTER};
}

function submitRegistrationForm(registrationData) {
	return dispatch => {
		ConnectionService.registerConnection(registrationData)
			.then(
				response => {
					if (response.ok) {
						dispatch(success(response));
					}
				}
			);
	};

	function success(response) {
		return {type: registrationConstants.REGISTRATION_ACCEPTED}
	}
}

function verifyEmail(token) {
	return dispatch => {
		ConnectionService.verifyRegistrationEmail(token)
			.then(async response => {
				if (response.ok) {
					const body = await response.json();

					if (body.success) {
						dispatch(success(response));
					} else {
						dispatch(failure(response));
					}
				} else {
					dispatch(failure(response));
				}
			});
	};

	function success(response) {
		return {type: registrationConstants.EMAIL_VERIFIED}
	}

	function failure(response) {
		return {type: registrationConstants.EMAIL_VERIFY_ERROR, errorMessage: response.message}
	}
}

function submitContactForm(formData) {
	return dispatch => {
		ContactService.submitContactForm(formData)
			.then(
				response => {
					if (response.ok) {
						dispatch(closeContactPopup());
					}
				}
			);
	};
}

function openContactPopup() {
	return {
		type: registrationConstants.OPEN_CONTACT_POPUP
	}
}

function closeContactPopup() {
	return {
		type: registrationConstants.CLOSE_CONTACT_POPUP
	}
}
