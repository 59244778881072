import {AppConfig} from "../config/app.config";
import notify from 'devextreme/ui/notify';

async function handleResponse(response, returnResponse = false) {
	const clone = response.clone(),
	      body = await response.json();

	if (!response.ok) {
		if (body.message) {
			notify({
				message:  body.message,
				position: {
					at:     'top center',
					offset: '0 60'
				}
			}, 'error', 3000);
		}
	} else {
		if (body.toast) {
			notify({
				message: body.toast.message,
				position: {
					at: 'top center',
					offset: '0 60'
				}
			}, body.toast.type, 3000);
		}
	}

	return returnResponse ? clone : body;
}

class HttpService {
	token = localStorage.getItem('token');

	getHeaders() {
		return {
			'Content-Type':  'application/json',
			'Authorization': `Bearer ${this.token}`
		};
	};

	get(url, returnResponse = false) {
		const requestOptions = {
			method:  'GET',
			headers: this.getHeaders()
		};

		return fetch(`${AppConfig.apiUrl}${url}`, requestOptions)
			.then(response => handleResponse(response, returnResponse));
	}

	post(url, body, returnResponse = false) {
		const requestOptions = {
			method:  'POST',
			headers: this.getHeaders(),
			body:    JSON.stringify(body)
		};

		return fetch(`${AppConfig.apiUrl}${url}`, requestOptions)
			.then(response => handleResponse(response, returnResponse));
	}

	patch(url, body, returnResponse = false) {
		const requestOptions = {
			method:  'PATCH',
			headers: this.getHeaders(),
			body:    JSON.stringify(body)
		};

		return fetch(`${AppConfig.apiUrl}${url}`, requestOptions)
			.then(response => handleResponse(response, returnResponse));
	}

	delete(url, returnResponse = false) {
		const requestOptions = {
			method:  'DELETE',
			headers: this.getHeaders()
		};

		return fetch(`${AppConfig.apiUrl}${url}`, requestOptions)
			.then(response => handleResponse(response, returnResponse));
	}


}

export default new HttpService();
