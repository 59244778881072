import {editMyInfoConstants} from "../../config/constants";

const initialState = {
	editMyInfoRequestOpen: false,
	editRequestAccepted:   false,
	editMyInfoPopupOpen:   false,
	editingConnection:     null,
	editSubmitted:         false,
	editRequestToken:      null
};

export function editMyInfo(state = initialState, action) {
	switch (action.type) {
		case editMyInfoConstants.OPEN_EDIT_MY_INFO_REQUEST:
			return {
				...state,
				editMyInfoRequestOpen: true
			};
		case editMyInfoConstants.CLOSE_EDIT_MY_INFO_REQUEST:
			return {
				...state,
				editMyInfoRequestOpen: false
			};
		case editMyInfoConstants.EDIT_MY_INFO_REQUEST_ACCEPTED:
			return {
				...state,
				editRequestAccepted: true
			};
		case editMyInfoConstants.OPEN_EDIT_MY_INFO_POPUP:
			window.history.replaceState({}, document.title, '/');

			return {
				...state,
				editMyInfoPopupOpen: true,
				editingConnection:   action.editingConnection,
				editRequestToken:    action.editRequestToken
			};
		case editMyInfoConstants.EDIT_MY_INFO_ACCEPTED:
			return {
				...state,
				editSubmitted:     true,
				editingConnection: null
			};
		case editMyInfoConstants.CLOSE_EDIT_MY_INFO_POPUP:
			return {
				...state,
				editMyInfoPopupOpen: false
			};
		default:
			return state;
	}
}
