import {connectionsConstants} from "../../config/constants";

const initialState = {
	connections:         [],
	rejectingConnection: null,
	rejectionPopupOpen:  false
};

export function connections(state = initialState, action) {
	switch (action.type) {
		case connectionsConstants.RECEIVE_CONNECTIONS:
			return {
				...state,
				connections: action.connections
			};
		case connectionsConstants.OPEN_REJECTION_POPUP:
			return {
				...state,
				rejectionPopupOpen:  true,
				rejectingConnection: action.rejectingConnection
			};
		case connectionsConstants.CLOSE_REJECTION_POPUP:
			return {
				...state,
				rejectionPopupOpen:  false,
				rejectingConnection: null
			};
		default:
			return state;
	}
}
