import {AuthenticationService} from "../../services/authentication.service";
import {userConstants} from "../../config/constants";
import notify from "devextreme/ui/notify";

export const authenticationActions = {
	login,
	logout,
	openLoginPopup,
	closeLoginPopup,
	validateToken,
	requestPasswordChange,
	changePassword,
	openChangePwPopup,
	closeChangePwPopup,
	changePwError,
	checkedSessionNoLogin
};

function validateToken(token) {
	return dispatch => {
		AuthenticationService.validateToken(token)
			.then(async response => {
				if (response && response.ok) {
					const body = await response.json();

					dispatch(success(body));

					if (body && body.requirePwChange) {
						dispatch(openPwChange());
					}
				} else {
					dispatch(checkedSessionNoLogin());
				}
			})
			.catch(() => {
				dispatch(checkedSessionNoLogin());
			});
	};

	function success(user) {
		return {type: userConstants.RECEIVE_SESSION, user}
	}

	function openPwChange() {
		return {type: userConstants.OPEN_CHANGE_PW};
	}
}

function login(username, password) {
	return dispatch => {
		AuthenticationService.login(username, password)
			.then(
				response => {
					dispatch(success(response));
				},
				error => {
					dispatch(failure(error));
				}
			);
	};

	function success(response) {
		return {type: userConstants.LOGIN_SUCCESS, user: response.user}
	}

	function failure(errorMessage) {
		return {type: userConstants.LOGIN_FAILURE, errorMessage}
	}
}

function requestPasswordChange(email) {
	return dispatch => {
		AuthenticationService.requestPasswordChange(email)
			.then(
				response => {

				},
				error => {
					dispatch(failure(error));
				}
			);
	};

	function failure(errorMessage) {
		return {type: userConstants.LOGIN_FAILURE, errorMessage}
	}
}

function changePassword(newPassword, oldPassword, pwChangeToken) {
	return dispatch => {
		AuthenticationService.changePassword(newPassword, oldPassword, pwChangeToken)
			.then(
				body => {
					if (body.success) {
						if (body.message) {
							notify({
								message:  body.message,
								position: {
									at:     'top center',
									offset: '0 60'
								}
							}, 'success', 10000);
						}
					}

					dispatch(success(body));
				},
				error => {
					dispatch(failure(error));
				}
			);
	};

	function success() {
		return {type: userConstants.CHANGE_PW_SUCCESS}
	}

	function failure(errorMessage) {
		return {type: userConstants.CHANGE_PW_FAILURE, errorMessage}
	}
}

function logout() {
	AuthenticationService.logout();
	return {type: userConstants.LOGOUT};
}

function openLoginPopup() {
	return {type: userConstants.OPEN_LOGIN};
}

function closeLoginPopup() {
	return {type: userConstants.CLOSE_LOGIN};
}

function openChangePwPopup(pwChangeToken) {
	return {type: userConstants.OPEN_CHANGE_PW, pwChangeToken};
}

function closeChangePwPopup() {
	return {type: userConstants.CLOSE_CHANGE_PW};
}

function changePwError(errorMessage) {
	return {type: userConstants.CHANGE_PW_FAILURE, errorMessage};
}

function checkedSessionNoLogin() {
	return {type: userConstants.NO_SESSION};
}
