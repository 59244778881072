export const userConstants = {
	GET_SESSION:       'GET_SESSION',
	RECEIVE_SESSION:   'RECEIVE_SESSION',
	INVALID_SESSION:   'INVALID_SESSION',
	NO_SESSION:        'NO_SESSION',
	LOGIN_REQUEST:     'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS:     'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE:     'USERS_LOGIN_FAILURE',
	LOGOUT:            'USERS_LOGOUT',
	OPEN_LOGIN:        'OPEN_LOGIN',
	CLOSE_LOGIN:       'CLOSE_LOGIN',
	CHANGE_PW_REQUEST: 'CHANGE_PW_REQUEST',
	CHANGE_PW_SUCCESS: 'CHANGE_PW_SUCCESS',
	CHANGE_PW_FAILURE: 'CHANGE_PW_FAILURE',
	OPEN_CHANGE_PW:    'OPEN_CHANGE_PW',
	CLOSE_CHANGE_PW:   'CLOSE_CHANGE_PW'
};

export const connectionsConstants = {
	RECEIVE_CONNECTIONS:   'RECEIVE_CONNECTIONS',
	ADD_CONNECTION:        'ADD_CONNECTION',
	UPDATE_CONNECTION:     'UPDATE_CONNECTION',
	REMOVE_CONNECTION:     'REMOVE_CONNECTION',
	APPROVE_CONNECTION:    'APPROVE_CONNECTION',
	OPEN_REJECTION_POPUP:  'OPEN_REJECTION_POPUP',
	CLOSE_REJECTION_POPUP: 'CLOSE_REJECTION_POPUP'
};

export const registrationConstants = {
	OPEN_REGISTER:                 'OPEN_REGISTER',
	CLOSE_REGISTER:                'CLOSE_REGISTER',
	SUBMIT_REGISTRATION_FORM:      'SUBMIT_REGISTRATION_FORM',
	REGISTRATION_ACCEPTED:         'REGISTRATION_ACCEPTED',
	REGISTRATION_ERROR:            'REGISTRATION_ERROR',
	EMAIL_VERIFIED:                'EMAIL_VERIFIED',
	EMAIL_VERIFY_ERROR:            'EMAIL_VERIFY_ERROR',
	OPEN_CONTACT_POPUP:            'OPEN_CONTACT_POPUP',
	CLOSE_CONTACT_POPUP:           'CLOSE_CONTACT_POPUP'

};

export const editMyInfoConstants = {
	OPEN_EDIT_MY_INFO_REQUEST:     'OPEN_EDIT_MY_INFO_REQUEST',
	CLOSE_EDIT_MY_INFO_REQUEST:    'CLOSE_EDIT_MY_INFO_REQUEST',
	EDIT_MY_INFO_REQUEST_ACCEPTED: 'EDIT_MY_INFO_REQUEST_ACCEPTED',
	OPEN_EDIT_MY_INFO_POPUP:       'OPEN_EDIT_MY_INFO_POPUP',
	CLOSE_EDIT_MY_INFO_POPUP:      'CLOSE_EDIT_MY_INFO_POPUP',
	EDIT_MY_INFO_ACCEPTED:         'EDIT_MY_INFO_ACCEPTED'
};

export const REGISTRATION_REJECTED = 'rejected';
export const CONNECTION_STATUS     = {
	AWAITING_EDIT_APPROVAL: -2,
	REJECTED:               -1,
	AWAITING_APPROVAL:      0,
	APPROVED:               1
};
