import {userConstants} from "../../config/constants";

const initialState = {
	loggedIn:       false,
	loggingIn:      false,
	loginPopupOpen: false,
	loginError:     '',
	user:           {},
	checkedSession: false,
	pwChangeOpen:   false,
	pwChangeToken:  '',
	pwChangeError:  ''
};

export function authentication(state = initialState, action) {
	switch (action.type) {
		case userConstants.NO_SESSION:
			return {
				...state,
				loggedIn:       false,
				user:           {},
				checkedSession: true
			};
		case userConstants.RECEIVE_SESSION:
			return {
				...state,
				loggedIn:       true,
				user:           action.user,
				checkedSession: true
			};
		case userConstants.INVALID_SESSION:
			return {
				...state,
				loggedIn:       false,
				user:           {},
				checkedSession: true
			};
		case userConstants.LOGIN_SUCCESS:
			window.location.reload();
			return state;
		/*			return {
						...state,
						loggingIn:      false,
						loggedIn:       true,
						user:           action.user,
						loginPopupOpen: false,
						loginError:     ''
					};*/
		case userConstants.LOGIN_FAILURE:
			return {
				...state,
				loginError: action.errorMessage
			};
		case userConstants.LOGOUT:
			window.location.reload();
			return state;
		/*			return {
						...state,
						loggedIn: false,
						user:     {}
					};*/
		case userConstants.OPEN_LOGIN:
			return {
				...state,
				loginPopupOpen: true,
				loginError:     ''
			};
		case userConstants.CLOSE_LOGIN:
			return {
				...state,
				loginPopupOpen: false
			};
		case userConstants.OPEN_CHANGE_PW:
			return {
				...state,
				pwChangeOpen:  true,
				pwChangeToken: action.pwChangeToken
			};
		case userConstants.CLOSE_CHANGE_PW:
			window.history.replaceState({}, document.title, '/');

			return {
				...state,
				pwChangeOpen: false
			};
		case userConstants.CHANGE_PW_SUCCESS:
			return {
				...state,
				pwChangeOpen:   false,
				pwChangeToken:  '',
				checkedSession: true,
				user:           {
					...state.user,
					requirePwChange: 0
				}
			};
		case userConstants.CHANGE_PW_FAILURE:
			return {
				...state,
				pwChangeError: action.errorMessage
			};
		default:
			return state;
	}
}
